import React, {Component, Fragment} from "react";

class Success extends Component {
    constructor(props) {
    super(props);
    this.OnSubmitForm = React.createRef();
    this.state = {
        check_payment: ''
        };
    }

    async componentDidMount() {
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const userRes = await fetch(`${API_ADDRESS}check_payment_grid/`, {
        method: 'GET',
        headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    });
    const userData = await userRes.json();
    const check_payment = userData.payment;

    this.setState({ check_payment: check_payment }, () => {
        if (this.state.check_payment === 'grid') {
            this.checkpayGrid();
        } else if (this.state.check_payment === 'no_grid') {
            this.checkpay();
        } else if (this.state.check_payment === '') {
            sessionStorage.clear();
            alert('Оплата не была произведена');
        }
    });
}

    checkpayGrid() {
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    fetch(`${API_ADDRESS}env_url/`, {
        method: 'GET',
        headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(json => {
        const finiteUrl = json.finiteUrl;

        return fetch(`${API_ADDRESS}update_status/`, {
            method: 'POST',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            if (!response.ok) {
                sessionStorage.clear();
                alert('Оплата не прошла');
                throw new Error('bad response');
            }
            return finiteUrl;
        });
    })
    .then(finiteUrl => {
        window.location.href = 'https://' + finiteUrl + '/#/';
    })
    .catch(error => {
        console.error('Error:', error);
    });
}

    checkpay() {
    var delay = 1000; // milliseconds
    var before = Date.now();
    const url = document.location.href;
    const data = { order: url.slice(36, url.length - 8) };
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

    fetch(`${API_ADDRESS}env_url/`, {
        method: 'GET',
        headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(json => {
        const finiteUrl = json.finiteUrl;

        if (window.location !== 'https://' + finiteUrl + '/#/payment') {
            return fetch(`${API_ADDRESS}order_status/`, {
                method: 'POST',
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then(response => response.json()).then(orderStatusJson => {
                return { orderStatusJson, finiteUrl };
            });
        }
    })
    .then(({ orderStatusJson, finiteUrl }) => {
        if (orderStatusJson['status'] === 'succeeded') {
            return new Promise(resolve => setTimeout(resolve, delay)).then(() => {
                var request = JSON.parse(window.sessionStorage.request);
                return fetch(`${API_ADDRESS}inf/`, {
                    method: 'POST',
                    headers: {
                        Authorization: `JWT ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(request),
                }).then(response => response.json()).then(infJson => {
                    return { infJson, finiteUrl };
                });
            });
        } else {
            sessionStorage.clear();
            alert('Оплата не прошла');
            const { REACT_APP_SELF_ADDRESS, REACT_APP_API_ADDRESS, REACT_APP_ESIA_REDIRECT_LOGOUT_URL } = process.env;
            localStorage.removeItem('token');
            this.setState({ logged_in: false, username: '' });
            this.setState({ displayed_form: 'login' });
            clearInterval(this.timerID);
            if (this.state.esia_in === 'true') {
                this.setState({ esia_in: false });
                localStorage.setItem('esia_login', false);
                window.location.href = 'https://' + finiteUrl + '/#/';
            }
            return null;
        }
    })
    .then(({ infJson, finiteUrl }) => {
        if (infJson) {
            window.open('https://' + finiteUrl + '/#', "_self");
            sessionStorage.clear();
        }
    })
    .catch(error => {
        console.error('Error:', error);
    });
}




    render() {

        return(

        <div>

        <div>
             <p align="left">Оплата завершена. В случае успешной оплаты вы будете перенаправлены на главную страницу. </p>
        </div>

        </div>
        )
    }


}
export default Success;