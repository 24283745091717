import React, { Component } from 'react';
import Nav from './components/Nav';
import LoginForm from './components/LoginForm';
import Clock from './components/clock';
import './App.css';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayed_form: '',
      logged_in: localStorage.getItem('token') ? true : false,
      esia_in: localStorage.getItem('esia_login'),
      username: '',
      personname: 'ФИО',
      wrong_input: true,
      email: ''
    };
    this.changeTheme = this.changeTheme.bind(this);
    this.child = React.createRef();
  }


  componentDidMount() {
    var uri = window.location.href;

    if ( uri.includes('?add_user=1&username') ) {
      var params = uri.split('?')[1].split('&');
      var username = params[1].split('=')[1];
      var password = params[2].split('=')[1];
      var guid = params[3].split('=')[1].split('/')[0];
      console.log(username+password+guid);
      fetch(`${process.env.REACT_APP_API_ADDRESS}add_user?username=${username}&password=${password}&guid=${guid}`)
      .then(answer => {console.log(answer);})
    }
    const {REACT_APP_SELF_ADDRESS, REACT_APP_API_ADDRESS, REACT_APP_ESIA_REDIRECT_LOGOUT_URL} = process.env;
    let theme = localStorage.getItem("state-theme");
    document.getElementsByTagName('html')[0].setAttribute('data-theme', theme);
    if (this.state.logged_in) {
        clearInterval(this.timerID);
        this.timerID = setInterval(async () => {
           const requestOptions = {
             method: 'POST',
             headers: { 'Content-Type': 'application/json' },
             body: JSON.stringify({ token: localStorage.getItem('token')})
           };
           console.log(requestOptions);
           fetch(`${REACT_APP_API_ADDRESS}refresh-token/`, requestOptions).then(res => res.json())
           .then(json => {localStorage.setItem('token', json.token)});
         }, 900000);

      fetch(`${REACT_APP_API_ADDRESS}current_user/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(json => {
          console.log(json);
          console.log('token:', localStorage.getItem('token'));
          if(json.data !== undefined){
            this.setState({ username: json.data.username, personname: json.personname});
          }
        });
    }
    else {
      this.handle_logout();
    }
  }
  componentWillUnmount () {
    const REACT_APP_API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    clearInterval(this.timerID);
  }

  handle_login = (e, data) => {
    const REACT_APP_API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    this.timerID = setInterval(async () => {
       const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({ token: localStorage.getItem('token')})
       };
       fetch(`${REACT_APP_API_ADDRESS}refresh-token/`, requestOptions).then(res => res.json())
       .then(json => {localStorage.setItem('token', json.token)});
     }, 900000);
    this.child.current.restart();
    e.preventDefault();
    fetch(`${REACT_APP_API_ADDRESS}token-auth/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        localStorage.setItem('token', json.token);
        if (json.user !== undefined)
        {
          this.setState({
            logged_in: true,
            displayed_form: '',
            username: json.user.username,
            wrong_input: false
          });

          fetch(`${REACT_APP_API_ADDRESS}current_user/`, {
            headers: {
              Authorization: `JWT ${json.token}`
            }
          })
            .then(res => res.json())
            .then(json => {
              this.setState({ email: json.email, personname: json.personname});

              fetch(`${REACT_APP_API_ADDRESS}login_user/?email=${this.state.email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                         },
              });
            });
        }
        else {
          this.handle_logout()
          this.setState({
            logged_in: false,
            displayed_form: 'login',
            personname: 'person name',
            username: undefined,
            wrong_input: true,

          });
          document.querySelector(".warn_alert_bttn").click();
        }
      });
  };

  handle_logout = () => {
    const {REACT_APP_SELF_ADDRESS, REACT_APP_API_ADDRESS, REACT_APP_ESIA_REDIRECT_LOGOUT_URL} = process.env;
    localStorage.removeItem('token');
    this.setState({ logged_in: false, username: '' });
    this.setState({displayed_form:'login'});
    clearInterval(this.timerID);
    if(this.state.esia_in === 'true')
    {
      this.setState({esia_in: false})
      localStorage.setItem('esia_login', false);
      window.location.href=`${REACT_APP_ESIA_REDIRECT_LOGOUT_URL}`;
  };
}

  changeTheme = () => {
    const htmlTag = document.getElementsByTagName('html')[0]
    if (htmlTag.getAttribute('data-theme')==='dark') {
        //htmlTag.removeAttribute('data-theme')
        this.setState({'theme':'light'});
        htmlTag.setAttribute('data-theme', 'light');
        localStorage.setItem("state-theme", "light");
        return window.localStorage.setItem("site-theme",'light')
    }
    this.setState({'theme':'dark'});
    htmlTag.setAttribute('data-theme', 'dark');
    localStorage.setItem("state-theme", "dark");
    window.localStorage.setItem("site-theme", "dark");
  }

  display_form = form => {
    this.setState({
      displayed_form: form
    });
  }

  render() {
    if(this.state.logged_in===false && this.state.displayed_form !== 'login'){
      this.setState({displayed_form:'login'});
    }

    let form;
    switch (this.state.displayed_form) {
      case 'login':
        form = <LoginForm handle_login={this.handle_login} wrong_input={this.state.wrong_input}/>;
        break;
      default:
        form = null;
    }

    return (
      <>
      <Clock ref={this.child} logged_in={this.state.logged_in} logout={this.handle_logout}/>
      <div className="App">
      <div>
      </div>
      {this.state.displayed_form == 'login' ? (
        <>
        <div className="logoutTog">
          <label class="labelToggle">
            <div class="toggle">
              <input onClick={this.changeTheme} class="toggle-state" type="checkbox" name="check" value="check" />
              <div class="indicator"></div>
            </div>
          </label>
        </div>
        </>
      ):(
        <div className="tog">
          <label class="labelToggle">
            <div class="toggle">
              <input onClick={this.changeTheme} class="toggle-state" type="checkbox" name="check" value="check" />
              <div class="indicator"></div>
            </div>
          </label>
        </div>
      )}

        <Nav
          logged_in={this.state.logged_in}
          username={this.state.username}
          personname={this.state.personname}
          display_form={this.display_form}
          handle_logout={this.handle_logout}
        />
        {form}
      </div>
    </>
    );
  }
}

export default App;
